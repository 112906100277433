/* column_flex_p */
#contact{
    height: 100vh;
    position: relative;
    align-items: center;
    font-size: 1.2rem;
    background-image: url(../../../public/Images/bg2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.contact{
    display: flex;
    width: 86%;
    justify-content: space-evenly;
    align-items: center;
    line-height: 2rem;
    background-color: var(--black);
    color: var(--white);
    padding: 5% 0;
}

/* left content */
/* flex_p */
.left_contact{
    width: 30%;
}

.left_contact img{
    width: 100%;
}

/* right content */
.right_contact{
    width: 35%;
}

.right_contact form{
    display: grid;
}

.right_contact form input{
    margin: 2% 0;
    padding: 2%;
}

.right_contact form textarea{
    padding: 2%;
}

.right_contact form button{
    margin: 1rem 0 0 0.1rem;
    padding: 2%;
    width: 35%;
    background-color: var(--white);
    color: var(--black);
    border: none;
    transition: 0.4s ease-in-out;
}

.right_contact form button:hover{
    border-radius: 15px;
    cursor: pointer;
    background-color: var(--gray);
    
}

.right_contact label{
    font-family: arial;
}

#contact h1{
    margin-bottom: 2rem;
    color: var(--black);
    font-family: arial;
}

.line{
    background-color: var(--white);
    width: 0.3rem;
    height: 110%;
}

footer{
    position: absolute;
    color: var(--black);
    width: 100%;
    font-size: 1rem;
    bottom: 0;
    text-align: center;
}

footer h3{
    padding: 4vh;
}

#contact span{
    margin-top: 1rem;
}

@media screen and (max-width:768px){
    .left_contact img {
        width: 120%;
    }
}

@media screen and (max-width:540px){
    .right_contact h4{
        font-size: 1.4rem;
    }
    .right_contact h5{
        font-size: 1rem;
    }
}

@media screen and (max-width:430px){
    .contact{
        flex-direction: column;
        line-height: 1.5rem;
        gap: 1rem;
        padding: 7% 0;
    }

    .left_contact{
        width: 75%;
    }

    .right_contact{
        width: 80%;
    }

    .right_contact h4{
        margin-bottom: 0.2rem;
    }

    footer{
        font-size: 0.8rem;
    }
}