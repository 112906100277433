:root{
    --gray: #989898;
    --shadow: 0px 3px 10px 10px rgb( 0, 0, 0, 0.08);
    --white:#fefdfd;
    --black:#151515;
}

*{
    text-decoration: none;
    list-style-type: none;
    scroll-behavior: smooth; 
    font-family: "Gumela";
    font-weight: 500;
    margin: 0;
    padding: 0;
}

h1,h2,h3,h4,h5,p {
    cursor: default;
}

.flex_p{
    display: flex;
    align-items: center;
    justify-content: center;
}

.column_flex_p{
    display: flex;
    flex-direction: column;
    justify-content: center;
}



