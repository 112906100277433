/* column_flex_p */
.cards{
    width: 30%;
    color: var(--black);
    text-align: center;
    box-shadow: var(--shadow);
    transition: 0.25s ease-in-out;
    margin: 0 1%;
}

.card{
    background-color: var(--white);
    align-items: center;
    border-radius: 15px;
    padding: 20px;
    font-size: 60%;
}

.card:hover{
    padding: 25px;
    font-size: 65%;
    box-shadow: 8px 9px 14px 4px rgb(0, 0, 0, 0.25);
    transition: 0.25s ease-in-out;
}

.service_icon img{
    width: 100%;
    height: 100%;
    background-color: var(--black);
    border-radius: 100%;
    padding: 0.1rem;
}

/* flex_p */
.service_icon{
    width: 6.5rem;
    height: 6.5rem;
    border: 0.6rem solid var(--black);
    background-color: var(--white);
    border-radius: 100%;
    padding: 0.5rem;
    transition: 0.25s ease-in-out;
}

.card:hover .service_icon{
    background-color: var(--black);
    border: 0.6rem solid var(--gray);
    transition: 0.25s ease-in-out;
}

.card:hover .service_icon img{
    color: var(--black);
}

.card > h2 {
    margin: 3% 0 1% 0;
}

@media screen and (max-width:540px){
    .cards{
        width: 30%;
    }

    .card{
        font-size: 45%;
    }

    .card > p{
        display: none;
    }

    .service_icon{
        width: 4.5rem;
        height: 4.5rem;
    }

    .card:hover p{
        display: block;
    }
}

@media screen and (max-width:430px){
    .cards{
        width: 80%;
    }

    .card:hover{
        padding: 15px 10px;
        box-shadow: 8px 9px 14px 4px rgb(0, 0, 0, 0.25);
        transition: 0.25s ease-in-out;
    }

}