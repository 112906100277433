/* column_flex_p */
#services{
    height: 100vh;
    background-color: var(--white);
}

/* column_flex_p */
.bg{
    height: 65%;
    width: 100%;
    align-items: center;
    font-size: 1.5rem;
    background-image: url(../../../public/Images/bg6.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
}

.bg h1{
    color: var(--white);
    font-size: 3rem;
}

/* flex_p */
.services{
    position: relative;
    height: 65%;
    margin-top: 2rem;
    width: 95%;
}

@media screen and (max-width:768px) {
    .bg{
        height: 50%;
    }
}

@media screen and (max-width:430px){
    .bg{
        height: 100%;
    }

    .services{
        flex-direction: column;
        gap: 2%;
    }

    .bg h1 {
        font-size: 2rem;
        margin: 18px;
    }
}