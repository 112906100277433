/* flex_p */
#aboutMe{
    height: 100vh;
    background-image: url(../../../public/Images/bg2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    line-height: 1.5rem;
    font-size: 18px;
    gap: 5%;
}

/* right content */
/* column_flex_p */
.right_about{
    width: 50%;
    color: var(--black);
}

/* left content */
/* column_flex_p */
.left_about{ 
    width: 400px;
    height: 400px;
    border-radius: 50%;
}

/* rotation */
.flipCard{
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.left_about:hover .flipCard{
    transform: rotateY(180deg);
}

/* column_flex_p */
.left_about .aboutMeLogo{
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(300deg, var(--black), var(--black), var(--gray), var(--gray));
    animation: moving 5s ease infinite;
    background-size: 200%;
    box-shadow: var(--shadow);
    backface-visibility:hidden;
}

@keyframes moving {
    0%{
        background-position: 0% 50%;
    }
    50%{
        background-position: 80% 0%;
    }
    100%{
        background-position: 0% 50%;
    }
}

/* flex_p */
.left_about .hireMe{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: none;
    transform: rotateY(180deg);
    font-size: 110%;
    cursor: pointer;
    box-shadow: var(--shadow);
    background-color: none;
    backface-visibility:hidden;
}

.hireMe h1{
    background-color: var(--black);
    color: var(--white);
    padding: 105px 65px;
    border-radius: 100%;
    transition: 0.4s ease-in-out;
    cursor: pointer;
}

.right_about > h1{
    font-size: 2.7rem;
    font-weight: 700;
    margin-bottom: 2.5rem;
}

.right_about > h2{
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.right_about > h2 span{
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.right_about p{
    font-family: arial;
}

.resume{
    width: 6rem;
    font-family: arial;
    color: var(--white);
    text-align: center;
    margin-top: 1rem;
    background-color: var(--black);
    padding: 1.5%;
    border-radius: 5px;
    border: 1.8px solid var(--black);
    transition: 0.2s ease-in-out;

}

.resume:hover{
    color: var(--black);
    background-color: var(--white);
    border: 1.5px solid var(--black);

}



@media screen and (max-width:768px) {
    #aboutMe{
        flex-direction: column;
    }

    .right_about{
        width:85%;
    }

    .left_about{
        width: 365px;
        height: 365px;
    }

    .right_about > h1{
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
    
    .right_about > h2{
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
    
    .right_about > h2 span{
        font-size: 1rem;
    }

    .right_about > p {
        font-size: 1rem;
    }

    .right_about a {
        margin-top: 1rem;
    }
    
}

@media screen and (max-width:430px) {
    .left_about{
        width: 345px;
        height: 345px;
    }

    .right_about > h2 span{
        display: none;
        font-size: 1.1rem;
    }
}