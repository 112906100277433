.navbar{
    width: 100%;
    height: 10vh;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    z-index: 1000;
    transition: all .5s ease-in-out;
}

/* left content */
/* flex_p */
.left_nav{
    position: relative;
    left: 5vh;
    font-size: 2vh;
    gap: 1rem;
    color: var(--white);
}

/* right content */
.right_nav{
    display: flex;
    align-items: center;
    position: relative;
    right: 5vh;
    font-size: 2vh;
    gap: 2rem;
}

.right_nav .links{
    display: flex;
    gap: 2rem;
}


.right_nav a{
    color: var(--white);
}

/* scrolled */
.navbar.scrolled{
    height: 8vh;
    z-index: 1000;
    background-color: var(--white);
    box-shadow: var(--shadow);
}

.navbar.scrolled .left_nav{
    color: var(--black);
}

.navbar.scrolled > .right_nav a{
    color: var(--black);
}

@media screen and (max-width:768px){
    .right_nav a{
        display: none;
    }

    .navbar.scrolled{
        display: none;
    }
}
