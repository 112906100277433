/* column_flex_p */
#Home{
    height: 100vh;
    background-image: url(../../../public/Images/bg4.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    line-height: 9vh;
    background-size: cover;
    background-position: center;
}

#Home h1{
    color: var(--white);
    font-size: 6vh;
    text-align: center;
}

#Home > h1 > span{
    font-size: 8vh;
}

#Home > h2{
    color: var(--white);
    font-size: 5vh;
    text-align: center;
}

/* flex_p */
.icons{
    margin-top: 25px;
    gap: 18px;
}

.icons img:hover{
    width: 45px;

}

@media screen and (max-width:430px){
    #Home{
        line-height: 5vh;
    }
    #Home h1{
        font-size: 5vh;
    }

    #Home > h1 > span{
        font-size: 3vh;
    }
    
    #Home > h2{
        font-size: 3vh;
    }
}

